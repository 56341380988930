<template>
  <div id="app">
    <!-- <Header /> -->
    <router-view></router-view>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Bio from './components/Bio.vue'
// import Header from './components/header.vue'
// import Footer from './components/footer.vue'

export default {
  name: 'App',
  components: {
    // Bio,
    // Header,
    // Footer
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;700&display=swap');

$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$desktop-min: 1024px;
$desktop-l-min: 1200px;
$desktop-xl-min: 1440px;
$desktop-xxl-min: 1920px;
// Media mixins
@mixin for-mobile {
  @media (max-width: $desktop-min - 1px) {
    @content;
  }
}
@mixin for-desktop {
  @media (min-width: $desktop-min) {
    @content;
  }
}
// Visibility helpers
.mobile-only {
  @include for-desktop {
    display: none !important;
  }
}
.desktop-only {
  @include for-mobile {
    display: none !important;
  }
}

#app {
  // background: #101816;
  font-family: 'Playfair Display', serif;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
