<template>
  <div id="home" class="hello">
    <!-- <button @click="login()">login</button>
    <button @click="getData()">getData</button> -->
    <div class="basicInfo">  
      <div class="todayDate">
        {{ getDate() }}
      </div>
      <p class="lastUpdated">
        Actualizat acum {{ timePassed }} <img src="../assets/reload.png" @click="getData()">
      </p>
    </div>
    <div class="mainWrapper">
	    <div class="mainInfo tableWrapper">
	    	<SfIcon
	          icon="sort"
	          size="1.5rem"
	          color="#fff"
	          data-test="sf-wishlist-icon"
	          class="smallPadding"
	          @click="activeFilters = !activeFilters"
	        />
	        <div class="filterContainer" v-if="activeFilters">
	        	<div>
	        		<label for="fromDate">Select From Date:</label>
	        		<input v-model="filter.fromDate" type="date" name="fromDate" id="fromDate">
	        	</div>
	        	<div>
	        		<label for="ToDate">Select To Date:</label>
	        		<input v-model="filter.toDate" type="date" name="toDate" id="toDate">
	        	</div>
	        	<div class="filterBtn" @click="applyFilter()">Apply Filter</div>
	        </div>
		    <div class="tableScroll">
		    	<table>
			      	<thead>
			      		<th>
			      			<span>Data</span>
			      		</th>
			      		<th @click="setFilterType(1)" :class="{'activeItem': selectedColumn == 1}">
			      			<span>Temperatură</span>
			      		</th>
			      		<th @click="setFilterType(2)" :class="{'activeItem': selectedColumn == 2}">
			      			<span>Umiditate</span>
			      		</th>
			      		<th @click="setFilterType(3)" :class="{'activeItem': selectedColumn == 3}">
			      			<span>Presiune</span>
			      		</th>
			      		<th @click="setFilterType(4)" :class="{'activeItem': selectedColumn == 4}">
			      			<span>Nivel Fum</span>
			      		</th>
			      	</thead>
			      	<tbody>
				      	<tr
			      		  v-for="item in retrievedData"
			      		  :key="item.id"
				      	>
			      		  <td>{{ getShortDate(item.createdAt) }}</td>
			      		  <td :class="{'activeItem': selectedColumn == 1}">{{ item.temperature }}°C</td>
			      		  <td :class="{'activeItem': selectedColumn == 2}">{{ item.humidity }}%</td>
			      		  <td :class="{'activeItem': selectedColumn == 3}">{{ item.pressure }}hPa</td>
			      		  <td :class="{'activeItem': selectedColumn == 4}">{{ item.smokeLevel }}ppm</td>
				      	</tr>
				    </tbody>
			      </table>
		    </div>
	    </div>
	    <div class="chartWrapper" v-if="selectedColumn">
		    <LineChartGenerator
		      :key="selectedColumn"
			  :chart-options="chartOptions"
			  :chart-data="chartData"
			  :width="500"
			  :height="300"
			/>
	    </div>
	</div>
    <router-link to="/" class="noUnderline">
      <div class="buttonHistory">Intoarce-te acasă</div>
    </router-link>
  </div>
</template>

<script>
import {
	SfIcon
} from '@storefront-ui/vue';
import axios from 'axios'

import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)

export default {
  name: 'Bio',
  components: {
  	LineChartGenerator,
  	SfIcon
  },
  props: {
    msg: String
  },
  data () {
    return {
      filter: {
      	fromDate: '',
      	toDate: ''
      },
      activeFilters: false,
      selectedItem: '',
      selectedColumn: null,
      retrievedData: null,
      loginToken: '',
      activator: false,
      createdData: null,
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#EE964B',
            borderColor: 'rgba(255, 255, 255, .2)',
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
        	x: {
        		grid: {
        			color: 'rgba(255, 255, 255, .2)'
        		}
        	},
        	y: {
        		grid: {
        			color: 'rgba(255, 255, 255, .2)'
        		}
        	}
        }
      }
    }
  },
  computed: {
    state() {
      return this.name.length >= 4
    },
    invalidFeedback() {
      if (this.name.length > 0) {
        return 'Enter at least 4 characters.'
      }
      return 'Please enter something.'
    },
    timePassed () {
      let date = new Date()
      let lastDate = new Date(this.createdData)
      let timePassed = new Date() - (date - lastDate)

      var seconds = Math.floor((new Date() - timePassed) / 1000);
      var interval = seconds / 31536000;

      if (interval == 1) {
        return Math.floor(interval) + " an";
      } else if (interval > 1) {
        return Math.floor(interval) + " ani";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        return Math.floor(interval) + " lună";
      } else if (interval > 1) {
        return Math.floor(interval) + " luni";
      }
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        return Math.floor(interval) + " zi";
      } else if (interval > 1) {
        return Math.floor(interval) + " zile";
      }
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        return Math.floor(interval) + " oră";
      } else if (interval > 1) {
        return Math.floor(interval) + " ore";
      }
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        return Math.floor(interval) + " minut";
      } else if (interval > 1) {
        return Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) + " secunde";
    }
  },
  beforeMount() {
    this.login();
  },
  watch: {
    loginToken () {
      this.getData();
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    applyFilter () {
    	console.log(this.filter.fromDate)
    	console.log(this.filter.toDate)
    	axios
        .get('https://psi.apps.prestamaniacs.com/api/v1/data?page=1&limit=50&sort=-createdAt' + '&fromDateTime=' + this.filter.fromDate + '&toDateTime=' + this.filter.toDate, {
          'headers': { 'Authorization': `Bearer ${this.loginToken}` }
        })
        .then(res => {
          this.createdData = res.data.data.values[0].createdAt
          this.retrievedData = res.data.data.values
        })
    },
    setFilterType (id) {
      this.selectedColumn = id
      if (id == 1) {
      	this.chartData.datasets[0].label = 'Temperatură'
      	this.chartData.datasets[0].data = []
      	this.chartData.labels = []
      	for (let item of this.retrievedData) {
      	  this.chartData.labels.unshift(this.getShortDate(item.createdAt))
      	  this.chartData.datasets[0].data.unshift(item.temperature)
      	}
      } else if (id == 2) {
      	this.chartData.datasets[0].label = 'Umiditate'
      	this.chartData.datasets[0].data = []
      	this.chartData.labels = []
      	for (let item of this.retrievedData) {
      	  this.chartData.labels.unshift(this.getShortDate(item.createdAt))
      	  this.chartData.datasets[0].data.unshift(item.humidity)
      	}
      } else if (id == 3) {
      	this.chartData.datasets[0].label = 'Presiune'
      	this.chartData.datasets[0].data = []
      	this.chartData.labels = []
      	for (let item of this.retrievedData) {
      	  this.chartData.labels.unshift(this.getShortDate(item.createdAt))
      	  this.chartData.datasets[0].data.unshift(item.pressure)
      	}
      } else {
      	this.chartData.datasets[0].label = 'Nivel Fum'
      	this.chartData.datasets[0].data = []
      	this.chartData.labels = []
      	for (let item of this.retrievedData) {
      	  this.chartData.labels.unshift(this.getShortDate(item.createdAt))
      	  this.chartData.datasets[0].data.unshift(item.smokeLevel)
      	}
      }
    },
    setItem (id) {
      this.selectedItem = id
    },
    login () {
      axios
      .post('https://psi.apps.prestamaniacs.com/api/v1/users/login', {
        'email': 'ciprian.trandafir06@gmail.com',
        'password': "vg789.h.6;'HBGY^&*"
      })
      .then(res => {
        this.loginToken = res.data.token
        this.activator = true
      })
    },
    getDate (specificDate) {
	  const date = specificDate ? new Date(specificDate) : new Date()

      let dayName = this.capitalizeFirstLetter(date.toLocaleDateString("ro-RO", { weekday: 'long' }));
      let day = date.getDate();
      let monthName = this.capitalizeFirstLetter(date.toLocaleDateString('ro-RO', { month: 'long' }));

      return dayName + ', ' + day + ' ' + monthName
    },
    getShortDate (specificDate) {
      const date = specificDate ? new Date(specificDate) : new Date()

      let day = date.getDate();
      let monthName = this.capitalizeFirstLetter(date.toLocaleDateString('ro-RO', { month: 'short' }));
      // let year = date.getFullYear();
      let time = date.toLocaleTimeString('ro-RO')

      return day + ' ' + monthName.slice(0, -1) + ' (' + time.toString().slice(0, -3) + ')'
    },
    getData () {
      axios
      .get('https://psi.apps.prestamaniacs.com/api/v1/data?page=1&limit=50&sort=-createdAt', {
        'headers': { 'Authorization': `Bearer ${this.loginToken}` }
      })
      .then(res => {
        // console.log(res.data.data.values)
        this.createdData = res.data.data.values[0].createdAt
        this.retrievedData = res.data.data.values
      })
      .catch(err => {
        console.log(err)
      })
    },
    timeSince (date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;

      if (interval == 1) {
        return Math.floor(interval) + " an";
      } else if (interval > 1) {
        return Math.floor(interval) + " ani";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        return Math.floor(interval) + " lună";
      } else if (interval > 1) {
        return Math.floor(interval) + " luni";
      }
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        return Math.floor(interval) + " zi";
      } else if (interval > 1) {
        return Math.floor(interval) + " zile";
      }
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        return Math.floor(interval) + " oră";
      } else if (interval > 1) {
        return Math.floor(interval) + " ore";
      }
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        return Math.floor(interval) + " minut";
      } else if (interval > 1) {
        return Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) + " secunde";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$desktop-min: 1024px;
$desktop-l-min: 1200px;
$desktop-xl-min: 1440px;
$desktop-xxl-min: 1920px;
// Media mixins
@mixin for-mobile {
  @media (max-width: $desktop-min - 1px) {
    @content;
  }
}
@mixin for-desktop {
  @media (min-width: $desktop-min) {
    @content;
  }
}
// Visibility helpers
.mobile-only {
  @include for-desktop {
    display: none !important;
  }
}
.desktop-only {
  @include for-mobile {
    display: none !important;
  }
}

////////////////////////////////////


.hello {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #011F40;
  color: #fff;
}

.todayDate {
  font-size: 2rem;
  font-weight: 300;
}
.lastUpdated {
  display: flex;
  align-items: center;
  font-size: .85rem;
  opacity: .55;
  margin: 0;

  img {
    filter: invert(1);
    height: 1.125rem;
    margin-left: 1rem;
    cursor: pointer;

    &:hover {
      animation: spin .75s ease 1;
    }
  }
}
.basicInfo {
  width: 30%;
}
.mainInfo {
  display: flex;
  position: relative;
  flex: 1 1 60%;
  width: 50%;
  margin: 6rem 2rem;
  overflow-y: scroll;
  text-align: center;
}

.mainInfo table {
	width: 100%;

	th {
		&:hover {
  			background: rgba(255, 255, 255, .05);
  		}
	}
	th, td {
		border-bottom: 1px solid rgba(255, 255, 255, .2);
  		padding: 0.35rem 0;
  		cursor: pointer;
	}
}

.filterContainer {
	position: absolute;
    left: 24px;
    background: #011F40;
    border: 1px solid #D6E3F8;
    padding: 2rem 1rem;

    input {
    	width: 100%;
	    padding: .35rem 0;
	    margin-bottom: 1rem;
    }
}

.divider {
  height: 8rem;
  width: 2px;
  background: #D6E3F8;
}
.temperature {
  display: flex;
  color: #EE964B;
  font-size: 8rem;

  span {
    font-size: 2rem;
    margin-top: 2.5rem;
    margin-left: .5rem;
  }
}
.secondaryInfo {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #26C485;

  span {
    display: block;
    font-size: .85rem;
    letter-spacing: 1px;
    color: #D6E3F8;
  }
}
.mainWrapper {
	display: flex;
	justify-content: space-between;
	height: 30rem;
	width: 80%;
}
.buttonHistory {
  width: 30rem;
  height: 3rem;
  padding: .75rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  color: #D6E3F8;
  border: 1px solid #D6E3F8;
  transition: all .35s ease;

  &:hover {
    background: rgba(0, 0, 0, .2);
  }
}

.filterBtn {
  width: 100%;
  height: 2.5rem;
  padding: .5rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  color: #D6E3F8;
  border: 1px solid #D6E3F8;
  transition: all .35s ease;
  margin-top: 1rem;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, .2);
  }
}

.noUnderline {
  text-decoration: none !important;
}

.activeItem {
	background: rgba(255, 255, 255, .2) !important;
}

.chartWrapper {
	margin: 6rem 2rem;
	transform: translateY(-8px);
	flex: 1 1 20%;
}

.tableScroll {
	flex: 1;
}

.smallPadding {
	margin: 0.35rem 0;
	cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, .2); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, .8); 
}

@keyframes spin { 
    100% { 
        -webkit-transform: rotate(180deg); 
        transform:rotate(180deg); 
    } 
}
</style>
