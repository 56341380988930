<template>
  <div id="home" class="hello">
    <!-- <button @click="login()">login</button>
    <button @click="getData()">getData</button> -->
    <div class="basicInfo">  
      <div class="todayDate">
        {{ getDate }}
      </div>
      <p class="lastUpdated">
        Actualizat acum {{ timePassed }} <img src="../assets/reload.png" @click="getData()">
      </p>
    </div>
    <div class="mainInfo">
      <div class="main">
        <div class="temperature">
          {{ temperature }} <span>°C</span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="second">
        <div class="secondaryInfo">
          <div class="humidity">
            {{ humidity }} %
          </div>
          <span>Umiditate</span>
        </div>
        <div class="secondaryInfo">
          <div class="pressure">
            {{ pressure }} hPa
          </div>
          <span>Presiune</span>
        </div>
        <div class="secondaryInfo">
          <div class="smokeLevel">
            {{ smokeLevel }} ppm
          </div>
          <span>Nivel Fum</span>
        </div>
      </div>
    </div>
    <router-link to="/history" class="noUnderline">
      <div class="buttonHistory">Vezi istoric</div>
    </router-link>
  </div>
</template>

<script>
import {
  // SfHero,
  // SfCard,
  // SfSection,
  // SfCharacteristic
} from '@storefront-ui/vue';
import axios from 'axios'

export default {
  name: 'Bio',
  data() {
    return {
      retrievedData: null,
      loginToken: '',
      activator: false,
      temperature: null,
      humidity: null,
      pressure: null,
      smokeLevel: null,
      createdData: null
    }
  },
  props: {
    msg: String
  },
  components: {
    // SfHero,
    // SfCard,
    // SfSection,
    // SfCharacteristic
  },
  computed: {
    state() {
      return this.name.length >= 4
    },
    invalidFeedback() {
      if (this.name.length > 0) {
        return 'Enter at least 4 characters.'
      }
      return 'Please enter something.'
    },
    getDate () {
      const date = new Date();
      let dayName = this.capitalizeFirstLetter(date.toLocaleDateString("ro-RO", { weekday: 'long' }));
      let day = date.getDate();
      let monthName = this.capitalizeFirstLetter(date.toLocaleDateString('ro-RO', { month: 'long' }));

      return dayName + ', ' + day + ' ' + monthName
    },
    timePassed () {
      let date = new Date()
      let lastDate = new Date(this.createdData)
      let timePassed = new Date() - (date - lastDate)

      var seconds = Math.floor((new Date() - timePassed) / 1000);
      var interval = seconds / 31536000;

      if (interval == 1) {
        return Math.floor(interval) + " an";
      } else if (interval > 1) {
        return Math.floor(interval) + " ani";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        return Math.floor(interval) + " lună";
      } else if (interval > 1) {
        return Math.floor(interval) + " luni";
      }
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        return Math.floor(interval) + " zi";
      } else if (interval > 1) {
        return Math.floor(interval) + " zile";
      }
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        return Math.floor(interval) + " oră";
      } else if (interval > 1) {
        return Math.floor(interval) + " ore";
      }
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        return Math.floor(interval) + " minut";
      } else if (interval > 1) {
        return Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) + " secunde";
    }
  },
  beforeMount() {
    this.login();
  },
  watch: {
    loginToken () {
      this.getData();
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    login () {
      axios
      .post('https://psi.apps.prestamaniacs.com/api/v1/users/login', {
        'email': 'ciprian.trandafir06@gmail.com',
        'password': "vg789.h.6;'HBGY^&*"
      })
      .then(res => {
        this.loginToken = res.data.token
        this.activator = true
      })
    },
    getData () {
      axios
      .get('https://psi.apps.prestamaniacs.com/api/v1/data?page=1&limit=25', {
        'headers': { 'Authorization': `Bearer ${this.loginToken}` }
      })
      .then(res => {
        this.retrievedData = res
        this.createdData = res.data.data.values[0].createdAt
        this.temperature = res.data.data.values[0].temperature
        this.humidity = res.data.data.values[0].humidity
        this.pressure = res.data.data.values[0].pressure
        this.smokeLevel = res.data.data.values[0].smokeLevel
        console.log(res.data.data.values[0])
      })
      .catch(err => {
        console.log(err)
      })
    },
    timeSince (date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;

      if (interval == 1) {
        return Math.floor(interval) + " an";
      } else if (interval > 1) {
        return Math.floor(interval) + " ani";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        return Math.floor(interval) + " lună";
      } else if (interval > 1) {
        return Math.floor(interval) + " luni";
      }
      interval = Math.floor(seconds / 86400);
      if (interval == 1) {
        return Math.floor(interval) + " zi";
      } else if (interval > 1) {
        return Math.floor(interval) + " zile";
      }
      interval = Math.floor(seconds / 3600);
      if (interval == 1) {
        return Math.floor(interval) + " oră";
      } else if (interval > 1) {
        return Math.floor(interval) + " ore";
      }
      interval = Math.floor(seconds / 60);
      if (interval == 1) {
        return Math.floor(interval) + " minut";
      } else if (interval > 1) {
        return Math.floor(interval) + " minute";
      }
      return Math.floor(seconds) + " secunde";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1023px;
$desktop-min: 1024px;
$desktop-l-min: 1200px;
$desktop-xl-min: 1440px;
$desktop-xxl-min: 1920px;
// Media mixins
@mixin for-mobile {
  @media (max-width: $desktop-min - 1px) {
    @content;
  }
}
@mixin for-desktop {
  @media (min-width: $desktop-min) {
    @content;
  }
}
// Visibility helpers
.mobile-only {
  @include for-desktop {
    display: none !important;
  }
}
.desktop-only {
  @include for-mobile {
    display: none !important;
  }
}

////////////////////////////////////


.hello {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #011F40;
  color: #fff;
}

.todayDate {
  font-size: 2rem;
  font-weight: 300;
}
.lastUpdated {
  display: flex;
  align-items: center;
  font-size: .85rem;
  opacity: .55;
  margin: 0;

  img {
    filter: invert(1);
    height: 1.125rem;
    margin-left: 1rem;
    cursor: pointer;

    &:hover {
      animation: spin .75s ease 1;
    }
  }
}
.basicInfo {
  width: 30%;
}
.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6rem 0;
  width: 35%;
}
.divider {
  height: 8rem;
  width: 2px;
  background: #D6E3F8;
}
.temperature {
  display: flex;
  color: #EE964B;
  font-size: 8rem;

  span {
    font-size: 2rem;
    margin-top: 2.5rem;
    margin-left: .5rem;
  }
}
.secondaryInfo {
  font-size: 1.5rem;
  margin: 1rem 0;
  color: #26C485;

  span {
    display: block;
    font-size: .85rem;
    letter-spacing: 1px;
    color: #D6E3F8;
  }
}
.buttonHistory {
  width: 30rem;
  height: 3rem;
  padding: .75rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  color: #D6E3F8;
  border: 1px solid #D6E3F8;
  transition: all .35s ease;

  &:hover {
    background: rgba(0, 0, 0, .2);
  }
}
.noUnderline {
  text-decoration: none !important;
}

@keyframes spin { 
    100% { 
        -webkit-transform: rotate(180deg); 
        transform:rotate(180deg); 
    } 
}
</style>
